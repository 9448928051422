import React from "react";
import styled from "styled-components";

export default function ProjectBox({ img, title, text, action}) {
  return (
    <Wrapper>
      <ImgBtn className="aniamte pointer" onClick={action ? () => action() : null}>
          <div className={"radius8"} style={{backgroundImage: "url(" + img + ")", width: "376px", height: "309px"}}></div>
        {/*<img className="radius8" src={img} alt="project"></img>*/}
      </ImgBtn>
      <h3 className="font20 extraBold">{title}</h3>
        { text && <p className="font13">{text}</p> }
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  margin-top: 30px;
  div {
    max-width: 100%;
    height: auto;
    margin: 20px 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: auto;
  }
  h3 {
    padding-bottom: 10px;
  }
`;
const ImgBtn = styled.button`
  background-color: transparent;
  border: 0px;
  outline: none;
  padding: 0px;
  margin: 0px;
  width: 100%;
  :hover > img {
    opacity: 0.5;
  }
`;