import * as React from "react";

function SvgComponent(props) {
  return (
      <svg width="100%" height="100%" viewBox="0 0 2139 1753" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;" {...props}>
        <g transform="matrix(1,0,0,1,-8125.56,0)">
            <g id="Vision" transform="matrix(0.694668,0,0,0.64451,7657.3,-2820.73)">
                <rect x="674.077" y="4376.56" width="3078.21" height="2719.12" style="fill:none;"/>
              <clipPath id="_clip1">
                    <rect x="674.077" y="4376.56" width="3078.21" height="2719.12"/>
                </clipPath>
              <g clip-path="url(#_clip1)">
                    <g transform="matrix(5.99807,0,0,6.46486,-9169.17,3155.59)">
                        <g transform="matrix(0.520543,0,0,0.520543,-1.13687e-13,0.129931)">
                            <path d="M3645.66,1105.66C3448.24,1105.66 3277.2,917.829 3226.64,856.486C3277.2,795.142 3447.84,607.314 3645.66,607.314C3842.88,607.314 4013.92,795.142 4064.48,856.486C4013.92,918.029 3843.48,1105.66 3645.66,1105.66ZM4132.21,837.303C4123.42,825.314 3913.62,542.374 3645.66,542.374C3377.51,542.374 3167.7,825.314 3158.91,837.303C3150.51,848.693 3150.51,864.278 3158.91,875.668C3167.7,887.657 3377.51,1170.6 3645.66,1170.6C3913.62,1170.6 4123.42,887.657 4132.21,875.668C4140.61,864.278 4140.61,848.693 4132.21,837.303Z" style="fill:rgb(136,136,138);"/>
                        </g>
                      <g transform="matrix(0.520543,0,0,0.520543,-1.13687e-13,0.129931)">
                            <path d="M3645.66,986.566C3573.93,986.566 3515.58,928.22 3515.58,856.486C3515.58,784.752 3573.93,726.605 3645.66,726.605C3717.2,726.605 3775.54,784.752 3775.54,856.486C3775.54,928.22 3717.2,986.566 3645.66,986.566ZM3645.66,661.464C3538.16,661.464 3450.64,748.984 3450.64,856.486C3450.64,963.987 3538.16,1051.51 3645.66,1051.51C3753.16,1051.51 3840.48,963.987 3840.48,856.486C3840.48,748.984 3753.16,661.464 3645.66,661.464Z" style="fill:rgb(136,136,138);"/>
                        </g>
                      <g transform="matrix(0.520543,0,0,0.520543,-1.13687e-13,0.129931)">
                            <path d="M3645.66,911.635C3675.83,911.635 3700.61,886.858 3700.61,856.486C3700.61,826.113 3675.83,801.536 3645.66,801.536C3615.29,801.536 3590.51,826.113 3590.51,856.486C3590.51,886.858 3615.29,911.635 3645.66,911.635Z" style="fill:rgb(229,30,37);"/>
                        </g>
                      <g transform="matrix(0.520543,0,0,0.520543,-1.13687e-13,0.129931)">
                            <path d="M3632.87,362.538C3647.46,362.538 3659.25,374.327 3659.25,388.914L3659.25,493.418C3659.25,507.805 3647.46,519.794 3632.87,519.794C3618.29,519.794 3606.5,507.805 3606.5,493.418L3606.5,388.914C3606.5,374.327 3618.29,362.538 3632.87,362.538Z" style="fill:rgb(229,30,37);"/>
                        </g>
                      <g transform="matrix(0.520543,0,0,0.520543,-1.13687e-13,0.129931)">
                            <path d="M3861.66,416.689C3874.25,423.882 3878.65,440.067 3871.45,452.656L3819.1,543.173C3811.91,555.761 3795.72,560.157 3783.13,552.764C3770.55,545.57 3766.15,529.385 3773.34,516.797L3825.7,426.28C3832.89,413.691 3849.07,409.295 3861.66,416.689Z" style="fill:rgb(229,30,37);"/>
                        </g>
                      <g transform="matrix(0.520543,0,0,0.520543,-1.13687e-13,0.129931)">
                            <path d="M3404.08,416.689C3391.49,423.882 3387.1,440.067 3394.49,452.656L3446.64,543.173C3453.84,555.761 3470.22,560.157 3482.81,552.764C3495.4,545.57 3499.59,529.385 3492.4,516.797L3440.25,426.28C3432.86,413.691 3416.67,409.295 3404.08,416.689Z" style="fill:rgb(229,30,37);"/>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
  );
}

export default SvgComponent;
