import * as React from "react";

function SvgComponent(props) {
  return (
      <svg width="100%" height="100%" viewBox="0 0 1767 2083" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;" {...props}>
        <g transform="matrix(1,0,0,1,-3961.31,0)">
            <g id="Values" transform="matrix(0.573791,0,0,0.765873,3574.53,-3351.88)">
                <rect x="674.077" y="4376.56" width="3078.21" height="2719.12" style="fill:none;"/>
              <g transform="matrix(7.26164,0,0,5.44042,-5865.21,3349.2)">
                    <g transform="matrix(0.520543,0,0,0.520543,-1.13687e-13,0.129931)">
                        <path d="M1911.25,672.454L2000.17,672.454L1953.41,803.934L1806.55,803.934L1911.25,672.454ZM2223.96,672.454L2270.72,803.934L2003.37,803.934L2050.32,672.454L2223.96,672.454ZM2362.84,672.454L2467.54,803.934L2320.87,803.934L2274.12,672.454L2362.84,672.454ZM2320.87,847.894L2466.94,847.894L2206.58,1166.6L2320.87,847.894ZM2003.56,847.894L2270.72,847.894L2138.04,1217.95L2003.56,847.894ZM1953.41,847.894L2068.71,1165L1807.54,847.894L1953.41,847.894ZM2387.01,628.694L1887.27,628.694L1730.02,826.113L2138.84,1322.66L2544.27,826.113L2387.01,628.694Z" style="fill:rgb(136,136,138);"/>
                    </g>
                <g transform="matrix(0.520543,0,0,0.520543,-1.13687e-13,0.129931)">
                        <path d="M2137.04,362.538C2151.63,362.538 2163.62,374.327 2163.62,388.914L2163.62,493.418C2163.62,507.805 2151.63,519.794 2137.04,519.794C2122.46,519.794 2110.67,507.805 2110.67,493.418L2110.67,388.914C2110.67,374.327 2122.46,362.538 2137.04,362.538Z" style="fill:rgb(229,30,37);"/>
                    </g>
                <g transform="matrix(0.520543,0,0,0.520543,-1.13687e-13,0.129931)">
                        <path d="M2365.83,416.689C2378.42,423.882 2382.82,440.067 2375.62,452.656L2323.27,543.173C2316.08,555.761 2299.89,560.157 2287.3,552.764C2274.72,545.57 2270.32,529.385 2277.51,516.797L2329.87,426.28C2337.06,413.691 2353.24,409.295 2365.83,416.689Z" style="fill:rgb(229,30,37);"/>
                    </g>
                <g transform="matrix(0.520543,0,0,0.520543,-1.13687e-13,0.129931)">
                        <path d="M1908.25,416.689C1895.66,423.882 1891.27,440.067 1898.66,452.656L1950.81,543.173C1958.01,555.761 1974.39,560.157 1986.98,552.764C1999.57,545.57 2003.96,529.385 1996.57,516.797L1944.42,426.28C1937.03,413.691 1920.84,409.295 1908.25,416.689Z" style="fill:rgb(229,30,37);"/>
                    </g>
                </g>
            </g>
        </g>
    </svg>
  );
}

export default SvgComponent;
