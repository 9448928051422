import * as React from "react";

function SvgComponent(props) {
  return (
      <svg width="100%" height="100%" viewBox="0 0 1662 1662" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" style={"fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"} {...props}>
        <g transform="matrix(1,0,0,1,-12289.8,0)">
            <g id="Team" transform="matrix(0.539816,0,0,0.611105,11925.9,-2674.53)">
                <rect x="674.077" y="4376.56" width="3078.21" height="2719.12" style="fill:none;"/>
              <g transform="matrix(2.00602,0,0,2.00602,-2226.5,-5220.17)">
                    <g transform="matrix(0.923463,0,0,0.815736,-10675.1,4376.56)">
                        <path d="M13956.5,499.505C13498,499.505 13125.7,871.759 13125.7,1330.27C13125.7,1788.79 13498,2161.04 13956.5,2161.04C14415,2161.04 14787.2,1788.79 14787.2,1330.27C14787.2,871.759 14415,499.505 13956.5,499.505ZM14513.6,1763.32L14381,1551.07C14324.9,1461.29 14226.6,1406.75 14120.7,1406.75L13792.2,1406.75C13686.4,1406.75 13588,1461.29 13531.9,1551.07L13399.3,1763.32C13431.7,1804.93 13468.7,1842.83 13509.4,1876.28L13646.5,1657.95C13656.3,1642.22 13675.4,1634.92 13693.3,1640.06C13711.1,1645.2 13723.4,1661.53 13723.4,1680.1L13723.4,1996.59C13796.4,2022.15 13874.8,2036.04 13956.5,2036.04C14038.1,2036.04 14116.5,2022.15 14189.5,1996.59L14189.5,1680.1C14189.5,1661.53 14201.8,1645.2 14219.7,1640.06C14237.5,1634.92 14256.6,1642.22 14266.5,1657.95L14403.5,1876.28C14444.3,1842.85 14481.2,1804.95 14513.6,1763.32ZM14272.9,1961.21L14272.9,1824.89L14335.9,1925.34C14315.6,1938.33 14294.5,1950.31 14272.9,1961.21ZM13577,1925.34L13640.1,1824.89L13640.1,1961.21C13618.4,1950.31 13597.4,1938.33 13577,1925.34ZM14564.9,1688.02C14626.7,1583.1 14662.2,1460.8 14662.2,1330.27C14662.2,940.749 14346,624.505 13956.5,624.505C13566.9,624.505 13250.7,940.749 13250.7,1330.27C13250.7,1460.8 13286.2,1583.1 13348.1,1688.02L13461.2,1506.91C13532.6,1392.76 13657.7,1323.41 13792.2,1323.41L14120.7,1323.41C14255.3,1323.41 14380.4,1392.76 14451.7,1506.91L14564.9,1688.02ZM13956.5,663.714C13781.8,663.714 13640.1,805.486 13640.1,980.109C13640.1,1154.73 13781.8,1296.5 13956.5,1296.5C14131.1,1296.5 14272.9,1154.73 14272.9,980.109C14272.9,805.486 14131.1,663.714 13956.5,663.714ZM13956.5,747.047C14085.1,747.047 14189.5,851.479 14189.5,980.109C14189.5,1108.74 14085.1,1213.17 13956.5,1213.17C13827.8,1213.17 13723.4,1108.74 13723.4,980.109C13723.4,851.479 13827.8,747.047 13956.5,747.047Z" style="fill:rgb(136,136,138);"/>
                    </g>
                <g id="Love" transform="matrix(5.28726,1.04155e-30,0,4.69446,-1254.79,4269.63)">
                        <path d="M661.5,286.9L660.9,286.9C657.2,287.1 653.6,289.3 651.4,292.6C649.4,295.7 648.9,299.4 650.1,302.6C651.5,306.4 655,309.8 658.1,312.8C658.8,313.5 659.5,314.2 660.1,314.8C662.3,317 664.5,319.1 666.6,321.1C667.8,322.2 669,323.4 670.2,324.6L671.5,325.9L672.9,324.7C676.9,321.3 680.9,317.5 684.8,313.4C685.2,313 685.6,312.5 686.1,312.1C689,309.1 692.7,305.4 693.5,300.9C694.1,297.2 693.2,293.6 691,291C688.9,288.5 685.8,287.1 682.4,287.1L681.9,287.1C676.5,287.3 673.3,290 671.4,292.4C669.6,289.9 666.3,286.9 661.5,286.9Z" style="fill:rgb(229,30,37);"/>
                    </g>
                </g>
            </g>
        </g>
    </svg>
  );
}

export default SvgComponent;
