import * as React from "react";

function SvgComponent(props) {
  return (
    <svg width="100%" height="100%" viewBox="0 0 1686 1686" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" xmlSpace="preserve" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:2;" {...props}>
        <g id="Mission" transform="matrix(0.547667,0,0,0.619992,-369.17,-2713.43)">
        <rect x="674.077" y="4376.56" width="3078.21" height="2719.12" style="fill:none;"/>
        <clipPath id="_clip1">
        <rect x="674.077" y="4376.56" width="3078.21" height="2719.12"/>
        </clipPath>
      <g clip-path="url(#_clip1)">
        <g transform="matrix(1.97726,0,0,1.97726,-1998.18,-5111.83)">
          <g transform="matrix(2.00292,0,0,1.76927,367.734,3973.47)">
            <path d="M879.794,551.965C1047.04,551.965 1182.72,687.64 1182.72,855.087C1182.72,1022.53 1047.04,1158.01 879.794,1158.01C712.348,1158.01 576.672,1022.53 576.672,855.087C576.672,687.64 712.348,551.965 879.794,551.965ZM879.794,466.443C1094.4,466.443 1268.44,640.483 1268.44,855.087C1268.44,1069.69 1094.4,1243.73 879.794,1243.73C665.191,1243.73 491.15,1069.69 491.15,855.087C491.15,640.483 665.191,466.443 879.794,466.443Z" style="fill:rgb(136,136,138);"/>
          </g>
          <g transform="matrix(2.00292,0,0,1.76927,367.734,3973.47)">
            <path d="M879.794,669.457C982.3,669.457 1065.22,752.581 1065.22,855.087C1065.22,957.593 982.3,1040.72 879.794,1040.72C777.288,1040.72 694.164,957.593 694.164,855.087C694.164,752.581 777.288,669.457 879.794,669.457ZM879.794,617.105C1011.27,617.105 1117.78,723.607 1117.78,855.087C1117.78,986.566 1011.27,1093.07 879.794,1093.07C748.314,1093.07 641.812,986.566 641.812,855.087C641.812,723.607 748.314,617.105 879.794,617.105Z" style="fill:rgb(136,136,138);"/>
          </g>
          <g transform="matrix(2.00292,0,0,1.76927,367.734,3973.47)">
            <path d="M879.794,747.186C939.34,747.186 987.695,795.542 987.695,855.087C987.695,914.632 939.34,962.988 879.794,962.988C820.249,962.988 771.893,914.632 771.893,855.087C771.893,795.542 820.249,747.186 879.794,747.186ZM879.794,716.814C956.124,716.814 1018.07,778.757 1018.07,855.087C1018.07,931.417 956.124,993.36 879.794,993.36C803.464,993.36 741.521,931.417 741.521,855.087C741.521,778.757 803.464,716.814 879.794,716.814Z" style="fill:rgb(136,136,138);"/>
          </g>
          <g transform="matrix(2.00292,0,0,1.76927,367.734,3973.47)">
            <path d="M882.392,828.312C882.392,828.112 882.192,827.912 882.192,827.912L759.504,705.224L759.504,628.295C759.504,622.5 757.306,617.105 753.31,613.109L624.428,482.828C618.234,476.634 609.042,474.835 601.049,478.032C593.057,481.429 587.861,489.222 587.861,498.014L587.861,562.355L521.922,562.355C513.13,562.355 505.337,567.55 502.14,575.743C498.743,583.735 500.542,592.927 506.736,599.122L637.017,729.402C641.013,733.398 646.408,735.596 652.203,735.596L729.132,735.596L851.82,858.284C851.82,858.284 852.02,858.484 852.219,858.484C860.612,866.477 873.2,866.676 881.792,858.484C890.584,849.892 890.384,836.704 882.392,828.312Z" style="fill:none;stroke:white;stroke-width:52.77px;"/>
          </g>
          <g transform="matrix(2.00292,0,0,1.76927,367.734,3973.47)">
            <path d="M882.392,828.312C882.392,828.112 882.192,827.912 882.192,827.912L759.504,705.224L759.504,628.295C759.504,622.5 757.306,617.105 753.31,613.109L624.428,482.828C618.234,476.634 609.042,474.835 601.049,478.032C593.057,481.429 587.861,489.222 587.861,498.014L587.861,562.355L521.922,562.355C513.13,562.355 505.337,567.55 502.14,575.743C498.743,583.735 500.542,592.927 506.736,599.122L637.017,729.402C641.013,733.398 646.408,735.596 652.203,735.596L729.132,735.596L851.82,858.284C851.82,858.284 852.02,858.484 852.219,858.484C860.612,866.477 873.2,866.676 881.792,858.484C890.584,849.892 890.384,836.704 882.392,828.312Z" style="fill:rgb(229,30,37);"/>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
}

export default SvgComponent;
